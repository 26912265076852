<template>
  <div class="chart-container">
    <apexchart
      :key="chartKey"
      type="line"
      :options="chartOptions"
      :series="formattedChart"
      :width="chartSeries[0].data.length <=15 ? '800' : '1500'"
      height="300px"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartKey: 0,
      formattedChart: [],
    };
  },
  watch: {
    chartOptions: {
      handler() {
        this.chartKey += 1;
      },
      deep: true,
    },
    chartSeries: {
      handler() {
        this.chartKey += 1;
      },
      deep: true,
    },
  },
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    chartSeries: {
      type: Array,
      required: true,
    },
  },
  created() {
    console.log('chartSeries', this.chartSeries[0].data.length);
    this.formattedChart = this.chartSeries.map((item) => ({
      ...item,
      data: item.data.map((value) => parseFloat(value / 100).toFixed(2)),
    }));

    this.chartOptions.yaxis = {
      labels: {
        formatter: (value) => `R$ ${this.numberToMonetary(value * 100)}`,
      },
    };

    // Garante responsividade na configuração do ApexCharts
    this.chartOptions.responsive = [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 300, // Reduz a altura em telas menores
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250, // Reduz ainda mais em telas pequenas
          },
        },
      },
    ];
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
</style>
