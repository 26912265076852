<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Ações">
      <b-row class="my-1 mt-2 d-flex align-items-center justify-content-end">
        <!-- <b-col sm="12" md="6" class="d-flex">
          <b-form-group class="flex-grow-1">
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-col> -->
        <b-col sm="12" md="6" class="d-flex justify-content-end">
          <b-button
            class="m-1"
            variant="primary"
            @click="$router.push({ name: 'nova-receita' })"
            block
          >
            Nova Receita +
          </b-button>
          <b-button
            class="m-1"
            variant="danger"
            @click="$router.push({ name: 'nova-despesa' })"
            block
          >
            Nova Despesa +
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- DATA INI -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Data inicio :</label
            >
            <b-form-input
              id="transactionDate"
              type="date"
              v-model="filters.startDate"
              @change="init"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- DATA FIM -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Data fim :</label
            >
            <b-form-input
              id="transactionDate"
              type="date"
              v-model="filters.endDate"
              @change="init"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            ><br />
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="selectedProjectId"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
              @change="getAllFinancialData"
            ></b-form-select>
            <label>
              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div></label
            >
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary"
              >Status :</label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="statusOptionSelected"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um status"
              @change="getAllFinancialData"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Receitas" class="bg-light-green">
      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="filter()"
        :fields="fields"
        show-empty
        class="p-1 w-100 table-details table-success"
        :per-page="perPage"
        :current-page="0"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong v-if="!isLoading">Não há dados para exibir</strong>
              <b-spinner
                v-else
                variant="primary"
                label="Carregando..."
              ></b-spinner>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(reference_date)="{ item }">
          {{ dateReferenceMonthFormatter(item.reference_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ `${numberToMonetary(item.value)}` }}
        </template>
        <template v-slot:cell(project_name)="{ item }">
          <small> {{ item.project_code }} </small>- {{ item.project_name }}
        </template>
        <template v-slot:cell(installments)="{ item }">
          {{ item.installments.length > 0 ? 'Sim' : 'Não' }}
        </template>
        <template v-slot:cell(actions)="{ item }">
          <ActionsRevenue
            @openModal="openEditModal(item)"
            @openModaDelete="openDeleteModal(item)"
            :item="item"
          />
        </template>
      </b-table>
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRowsRevenue"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="mr-2 d-flex justify-content-end">
        <strong>Total de receitas: {{ totalRowsRevenue }} </strong>
      </div>

      <!-- <Modal
        :modal="modal"
        :modalForm="modalForm"
        :editable="editable"
        :itemUpdate="itemUpdate"
        @updateList="handleUpdateList"
        @renderList="init"
        @closeModal="closeModal()"
      /> -->
    </b-card>

    <b-card title="Despesas de projetos / escritório" class="bg-light-red">
      <!-- Tabela principal  -->
      <b-table
        responsive="sm"
        small
        :items="itemsExpense"
        :fields="fieldsExpense"
        show-empty
        class="p-1 w-100 table-details table-danger"
        :per-page="perPage"
        :current-page="0"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong v-if="!isLoading">Não há dados para exibir</strong>
              <b-spinner
                v-else
                variant="primary"
                label="Carregando..."
              ></b-spinner>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(transaction_date)="{ item }">
          {{ dateFormatter(item.transaction_date) }}
        </template>
        <template v-slot:cell(reference_date)="{ item }">
          {{ dateReferenceMonthFormatter(item.reference_date) }}
        </template>
        <template v-slot:cell(value)="{ item }">
          {{ `${numberToMonetary(item.value)}` }}
        </template>
        <template v-slot:cell(project_name)="{ item }">
          <small> {{ item.project_code }} </small>- {{ item.project_name }}
        </template>
        <template v-slot:cell(supplier.supplier_name)="{ item }">
          {{
            item.supplier && item.supplier.supplier_name
              ? item.supplier.supplier_name
              : 'Não informado'
          }}
        </template>
        <template v-slot:cell(actions)="{ item }">
          <ActionsExpense
            @openModal="openEditModal(item)"
            @openModaDelete="openDeleteModal(item)"
            :item="item"
          />
        </template>
      </b-table>
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRowsExpense"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="mr-2 d-flex justify-content-end">
        <strong>Total de Despesas: {{ totalRowsExpense }} </strong>
      </div>
    </b-card>

    <b-card title="Provisões">
      <!-- Tabela dos totais -->
      <b-table
        responsive="sm"
        small
        :items="totalRevenue"
        :fields="fieldsTotalRevenue"
        show-empty
        class="p-1 w-100 table-details table-success custom-rounded-top"
        style="margin-bottom: 0px"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong v-if="!isLoading">Não há dados para exibir</strong>
              <b-spinner
                v-else
                variant="primary"
                label="Carregando..."
              ></b-spinner>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(totalRealizado)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalRealizado)}` }}
        </template>
        <template v-slot:cell(totalPrevisto)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalPrevisto)}` }}
        </template>
        <template v-slot:cell(totalGeral)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalGeral)}` }}
        </template>
      </b-table>

      <b-table
        responsive="sm"
        small
        :items="totalExpense"
        :fields="fieldsTotalExpense"
        show-empty
        class="p-1 w-100 table-details table-danger custom-rounded-bottom"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong v-if="!isLoading">Não há dados para exibir</strong>
              <b-spinner
                v-else
                variant="primary"
                label="Carregando..."
              ></b-spinner>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(totalRealizado)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalRealizado)}` }}
        </template>
        <template v-slot:cell(totalPrevisto)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalPrevisto)}` }}
        </template>
        <template v-slot:cell(totalGeral)="{ item }">
          {{ `R$ ${numberToMonetary(item.totalGeral)}` }}
        </template>
      </b-table>
      <b-card-footer class="text-right font-weight-bold">
        Saldo Final:
        <span :class="totalBalance >= 0 ? 'text-success' : 'text-danger'">
          R$ {{ numberToMonetary(totalBalance) }}
        </span>
      </b-card-footer>
    </b-card>

    <!-- Grafico -->
    <!-- <b-card v-if="itemsRevenue && itemsRevenue.length > 0" title="Comparativo">
      <div
        class="d-flex justify-content-center align-items-center overflow-scroll-mobile"
      >
        <Chart :chart-options="customOptions" :chart-series="customSeries" />
      </div>
    </b-card>

    <b-card v-if="itemsExpense && itemsExpense.length > 0" title="Evolução">
      <div
        class="d-flex justify-content-center align-items-center overflow-scroll-mobile"
      >
        <Chart :chart-options="customOptions" :chart-series="customSeries" />
      </div>
    </b-card> -->

    <b-card
      class="overflow-scroll-mobile"
      v-if="itemsRevenue.length > 0 || itemsExpense.length > 0"
      title="Evolução Financeira"
    >
      <b-col>
        <Chart :chart-options="customOptions" :chart-series="customSeries" />
      </b-col>
    </b-card>

    <b-card
      v-if="costPerSupplier_data.render"
      title="Pagamentos por fornecedor"
    >
      <b-row class="w-100">
        <b-col cols="6" class="text-center mx-auto">
          <ProfitabilityChart :chartData="costPerSupplier_data" />
        </b-col>
        <b-col cols="6">
          <ProfitabilityChart :chartData="costPerSupplierTreeMap_data" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BFormInput,
  BFormGroup,
  BCol,
  BFormSelect,
  BAlert,
  BPagination,
  BCardFooter,
  BSpinner,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsRevenue from '../revenue/components/ActionsRevenue.vue';
import ActionsExpense from '../expense/components/ActionsExpense.vue';
import ProfitabilityChart from '@/components/Chart.vue';

//import Modal from './components/ModalRevenue.vue';
import Chart from './components/Chart.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BButton,
    // Modal,
    ActionsRevenue,
    ActionsExpense,
    BFormInput,
    BFormGroup,
    Chart,
    BCol,
    BFormSelect,
    BAlert,
    BPagination,
    BCardFooter,
    ProfitabilityChart,
    BSpinner,
  },
  data: () => ({
    modal: false,
    isLoading: false,
    showArquivded: false,
    costPerSupplier_data: {},
    costPerSupplierTreeMap_data: {},
    modalForm: false,
    editable: false,
    itemUpdate: null,
    selectedProjectId: null,
    search: '',
    perPage: 100000,
    currentPage: 1,
    totalRowsRevenue: 0,
    totalExpense: [],
    filterTrigged: false,
    projects: [],
    totalRowsExpense: 0,
    itemsExpense: [],
    filters: {
      startDate: null,
      endDate: null,
    },
    statusOptions: [
      {
        value: 'todos',
        name: 'Todos',
      },
      {
        value: 'previsto',
        name: 'Previsto',
      },
      {
        value: 'realizado',
        name: 'Realizado',
      },
    ],
    statusOptionSelected: 'todos',
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data',
        sortable: true,
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: false,
      },
      {
        key: 'project_name',
        label: 'Cod - Projeto',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Referência',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'installments',
        label: 'Parcelamento',
        sortable: true,
      },
    ],

    fieldsExpense: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data',
        sortable: true,
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: false,
      },
      {
        key: 'project_name',
        label: 'Cod - Projeto',
        sortable: false,
      },

      {
        key: 'reference_date',
        label: 'Referência',
        sortable: true,
      },
      {
        key: 'supplier.supplier_name',
        label: 'Fornecedor',
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
      },
      {
        key: 'type',
        label: 'Condição de pagamento',
        sortable: true,
      },
    ],

    fieldsTotalRevenue: [
      {
        key: 'totalPrevisto',
        label: 'A RECEBER',
        sortable: false,
      },
      {
        key: 'totalRealizado',
        label: 'RECEBIDO',
        sortable: false,
      },
      {
        key: 'totalGeral',
        label: 'Receitas totais',
        sortable: false,
      },
    ],
    fieldsTotalExpense: [
      {
        key: 'totalPrevisto',
        label: 'A PAGAR',
        sortable: false,
      },
      {
        key: 'totalRealizado',
        label: 'PAGO',
        sortable: false,
      },
      {
        key: 'totalGeral',
        label: 'Despesas totais',
        sortable: false,
      },
    ],

    itemsRevenue: [],
    totalRevenue: [],
    costsCategoryList: false,
    customOptions: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
    },
    customSeries: [
      {
        name: 'Receitas Realizadas',
        data: [],
      },
    ],
  }),

  created() {
    this.init();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    totalBalance() {
      const revenue = this.totalRevenue.reduce(
        (sum, item) => sum + item.totalGeral,
        0
      );
      const expense = this.totalExpense.reduce(
        (sum, item) => sum + item.totalGeral,
        0
      );
      return revenue - expense;
    },
  },

  methods: {
    init() {
      // this.getAllRevenues();
      // this.getAllExpenses();
      this.getProjects();
      this.getAllFinancialData();
    },
    async getPage(val) {
      this.currentPage = val;
      if (this.filterTrigged) {
        await this.getFilter();
      } else {
        await this.getAllRevenues();
        await this.getAllExpenses();
      }
    },

    async getAllFinancialData() {
      this.isLoading = true;
      this.itemsRevenue = [];
      this.itemsExpense = [];

      let bodyRevenue = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null,
        project_id: this.selectedProjectId ? this.selectedProjectId : null,
        status: this.statusOptionSelected ? this.statusOptionSelected : null,
      };

      let bodyExpense = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null,
        project_id: this.selectedProjectId ? this.selectedProjectId : null,
        status: this.statusOptionSelected ? this.statusOptionSelected : null,
      };

      try {
        const [revenueResp, expenseResp] = await Promise.all([
          this.$store.dispatch('getAllRevenues', bodyRevenue),
          this.$store.dispatch('getAllExpenses', bodyExpense),
        ]);

        if (revenueResp) {
          this.itemsRevenue = revenueResp.data || [];
          this.totalRevenue = [revenueResp.totals];
          this.totalRowsRevenue = revenueResp.pagination.total;
        }

        if (expenseResp) {
          this.itemsExpense = expenseResp.data || [];
          this.totalExpense = [expenseResp.totals];
          this.totalRowsExpense = expenseResp.pagination.total;

          const supplierCost = this.itemsExpense.reduce((acc, item) => {
            if (item.supplier) {
              const { id, supplier_name } = item.supplier;
              const existingSupplier = acc.find((s) => s.supplier_id === id);

              if (existingSupplier) {
                existingSupplier.value += item.value;
              } else {
                acc.push({
                  supplier_id: id,
                  supplier_name,
                  value: item.value,
                });
              }
            }
            return acc;
          }, []);

          this.setcostPerSupplier_data(supplierCost);
          this.setcostPerSupplierTreeMap_data(supplierCost);
        }

        const sortedExpenses = [...this.itemsExpense].sort(
          (a, b) => new Date(a.transaction_date) - new Date(b.transaction_date)
        );

        this.processChartData(this.itemsRevenue, sortedExpenses);
        this.isLoading = false;
      } catch (error) {
        console.error('Erro ao buscar dados financeiros:', error);
      }
    },

    async processChartData(revenues, expenses) {
      const monthlyDataRevenue = {};
      const monthlyDataExpense = {};
      const chartCategories = new Set();

      // Processa Receitas
      revenues.forEach((revenue) => {
        const month = this.dateReferenceMonthFormatter(
          revenue.transaction_date
        );
        monthlyDataRevenue[month] =
          (monthlyDataRevenue[month] || 0) + revenue.value;
        chartCategories.add(month);
      });

      // Processa Despesas
      expenses.forEach((expense) => {
        const month = this.dateReferenceMonthFormatter(
          expense.transaction_date
        );
        monthlyDataExpense[month] =
          (monthlyDataExpense[month] || 0) + expense.value;
        chartCategories.add(month);
      });

      const sortedCategories = Array.from(chartCategories).sort();

      this.customOptions.xaxis.categories = sortedCategories;

      this.customSeries = [
        {
          name: 'Receitas',
          data: sortedCategories.map((month) => monthlyDataRevenue[month] || 0),
          color: '#28a745',
        },
        {
          name: 'Despesas',
          data: sortedCategories.map((month) => monthlyDataExpense[month] || 0),
          color: '#dc3545',
        },
      ];

      this.customOptions.dataLabels = {
        formatter: (val) => `R$ ${this.numberToMonetary(val)}`,
      };
      this.customOptions.yaxis = {
        labels: {
          formatter: (value) => `R$ ${this.numberToMonetary(value)}`,
        },
      };
    },

    async getAllRevenues() {
      this.itemsRevenue = [];

      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null,
      };
      this.$store
        .dispatch('getAllRevenues', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.itemsRevenue = resp.data;
            this.totalRevenue = arrayTranform;
            this.processChartData(resp.data);
            this.totalRowsRevenue = resp.pagination.total;
          }
          console.log(resp);
        })

        .catch((err) => {
          console.log(err);
        });
    },

    async getFilter() {
      this.items = [];
      this.totalRevenue = [];
      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: this.showArquivded,
        project_id: this.selectedProjectId,
        status: this.statusOptionSelected,
        page: this.currentPage,
        perPage: this.perPage,
        start_date: this.filters.startDate ? this.filters.startDate : null,
        end_date: this.filters.endDate ? this.filters.endDate : null,
      };

      this.$store
        .dispatch('getRevenues', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.items = resp.data;
            this.totalRevenue = arrayTranform;
            this.processChartData(resp.data);
            this.totalRowsRevenue = resp.pagination.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.projects = [];
          this.projects.push({
            id: null,
            name: 'Todos',
          });
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Controladores
    openEditModal(item) {
      this.modal = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modal = true;
      this.modalForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modal = true;
      this.modalForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modal = false;
      this.editable = false;
    },

    filter() {
      if (this.search.trim() === '') {
        return this.itemsRevenue;
      } else {
        return this.itemsRevenue.filter((item) => {
          const searchText = this.search.toLowerCase();
          return (
            item.project_name.toLowerCase().includes(searchText) ||
            item.project_code.toLowerCase().includes(searchText) ||
            item.description.toLowerCase().includes(searchText) ||
            item.value.toString().toLowerCase().includes(searchText) ||
            item.status.toLowerCase().includes(searchText)
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAllRevenues();
    },
    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getAllRevenues();
      this.getProjects();
    },
    handleFilterChange() {
      this.getFilter();
      this.filterTrigged = true;
    },

    //EXPENSES

    async getAllExpenses() {
      this.itemsExpense = [];
      let body = {
        workspace_id: this.currentWorkspace.id,
        archived: this.showArquivded,
        page: this.currentPage,
        perPage: this.perPage,
      };
      this.$store
        .dispatch('getAllExpenses', body)
        .then((resp) => {
          if (resp) {
            let arrayTranform = [];
            arrayTranform.push(resp.totals);
            this.itemsExpense = resp.data;

            const supplierCost = this.itemsExpense.reduce((acc, item) => {
              if (item.supplier) {
                const { id, supplier_name } = item.supplier;
                const existingSupplier = acc.find((s) => s.supplier_id === id);

                if (existingSupplier) {
                  existingSupplier.value += item.value;
                } else {
                  acc.push({
                    supplier_id: id,
                    supplier_name,
                    value: item.value,
                  });
                }
              }
              return acc;
            }, []);
            const sortedData = resp.data.sort(
              (a, b) =>
                new Date(a.transaction_date) - new Date(b.transaction_date)
            );

            this.totalExpense = arrayTranform;
            this.processChartData(sortedData);
            this.setcostPerSupplier_data(supplierCost);
            this.setcostPerSupplierTreeMap_data(supplierCost);

            this.totalRowsExpense = resp.pagination.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async setcostPerSupplier_data(props) {
      this.costPerSupplier_data = {};
      const data = [];
      const date = [];
      const project_ids = [];

      props.map((e) => {
        data.push(e.value / 100);
        date.push(e.supplier_name);
        project_ids.push(e.supplier_id);
      });

      // Criando referência local para numberToMonetary do mixin
      const numberToMonetary = this.numberToMonetary;

      this.costPerSupplier_data = {
        render: date.length > 0,
        width: '100%',
        height: date.length > 10 ? '1000px' : '400px',
        chartOptions: {
          chart: {
            type: 'bar',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const project_id = project_ids[dataIndex];

                if (project_id) {
                  this.$router.push(`/projetos/dashboard/${project_id}`);
                }
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '200px',
              borderRadiusApplication: 'end',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            categories: data,
          },
          xaxis: {
            tickAmount: 3,
            labels: {
              formatter: function (number) {
                if (isNaN(number)) return 'Invalid input';
                return `R$ ${numberToMonetary(number * 100)}`;
              },
            },
            categories: date,
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) return 'Invalid input';
                return `R$ ${numberToMonetary(number * 100)}`;
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Custo',
            data,
          },
        ],
      };
    },

    async setcostPerSupplierTreeMap_data(props) {
      this.costPerSupplierTreeMap_data = {};

      const chartData = props.map((e) => ({
        x: e.supplier_name,
        y: parseFloat(e.value.toFixed(2)),
        project_id: e.supplier_id,
      }));

      this.costPerSupplierTreeMap_data = {
        render: chartData.length > 0,
        width: '100%',
        height:
          chartData.length <= 10
            ? '400px'
            : chartData.length > 10
            ? '1000px'
            : chartData.length > 20
            ? '1500px'
            : '400px',
        chartOptions: {
          chart: {
            type: 'treemap',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const project_id = chartData[dataIndex].project_id;

                if (project_id) {
                  this.$router.push(`/projetos/dashboard/${project_id}`);
                }
              },
            },
          },
          tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
              const value = w.config.series[seriesIndex].data[dataPointIndex].y;
              const formattedValue = `R$ ${this.numberToMonetary(value)} `;
              const label = w.config.series[seriesIndex].data[dataPointIndex].x;

              return `
            <div style="padding: 10px; border: 1px solid #ddd; background: #fff;">
              <strong>${label}</strong><br />
              <span>${formattedValue}</span>
            </div>`;
            },
          },
          plotOptions: {
            treemap: {
              enableShades: true,
              distributed: true,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 1000,
                    color: '#f5a623',
                  },
                  {
                    from: 1001,
                    to: 5000,
                    color: '#7bd9a5',
                  },
                  {
                    from: 5001,
                    to: 10000,
                    color: '#008ffb',
                  },
                ],
              },
            },
          },
        },
        chartSeries: [
          {
            data: chartData,
          },
        ],
      };
    },
  },
};
</script>

<style>
.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: nowrap;
}
.table-details {
  max-height: 40vh;
  overflow-y: auto;
}
.bg-light-green {
  background-color: #d4edda !important;
}

.bg-light-red {
  background-color: #f8d7da !important;
}
.custom-rounded-top {
  border-radius: 12px 12px 0 0 !important;
  overflow: hidden;
}
.custom-rounded-bottom {
  border-radius: 0px 0px 12px 12px !important;
  overflow: hidden;
}
</style>
